import '../../styles/main.scss';
import { Heading, Text } from '@quality24/design-system';
import classNames from 'classnames';
import { HeadFC } from 'gatsby';
import * as React from 'react';

import SEO from '../../components/containers/SEO';
import MainTemplate from '../../components/templates/MainTemplate';
import SquareGraphic from '../../assets/graphic-1.inline.svg';

import * as styles from './privacy.module.scss';

const HeroContent = () => (
  <div className={classNames(styles.title, 'container')}>
    <Heading className="py-3" as="h1" size="lg2" weight="bold">
      Política de Privacidade
    </Heading>
  </div>
);

const PrivacyPage: React.FunctionComponent = () => {
  return (
    <MainTemplate heroContent={<HeroContent />}>
      <div className={classNames(styles.wrapper, 'container')}>
        <div className="d-flex flex-column gap-4">
          <Text>
            A Quality24 Tecnologia S.A., responsável pelo software de gestão de
            processos hospitalares Quality24, está comprometida com a
            privacidade e a proteção dos dados pessoais dos usuários e
            pacientes, em conformidade com a Lei Geral de Proteção de Dados
            (LGPD - Lei nº 13.709/2018). Esta Política de Privacidade explica
            como coletamos, usamos, armazenamos e protegemos as informações dos
            usuários e pacientes.
          </Text>

          <Heading as="h2" size="lg1" weight="bold">
            Dados Coletados
          </Heading>

          <Text>O Quality24 pode coletar os seguintes dados pessoais:</Text>

          <ul
            className="d-flex flex-column gap-2"
            style={{ listStyleType: 'disc', listStyle: 'inside' }}
          >
            <li>
              Dados de Identificação: Nome, CPF, data de nascimento, sexo.
            </li>

            <li>
              Dados de acesso: Endereço IP, navegador utilizado, registros de
              acesso e interações com o sistema.
            </li>
            <li>
              Dados profissionais (para médicos e funcionários): Nome, cargo,
              CPF e matrícula.
            </li>
          </ul>

          <Heading as="h2" size="lg1" weight="bold">
            Finalidade do Tratamento dos Dados
          </Heading>
          <Text>
            Os dados coletados são utilizados para as seguintes finalidades:
          </Text>

          <ul
            className="d-flex flex-column gap-2"
            style={{ listStyleType: 'disc', listStyle: 'inside' }}
          >
            <li>Atendimento a requisitos regulatórios e auditorias;</li>
            <li>Gerenciamento de processos hospitalares;</li>
            <li>Cumprimento de obrigações legais e regulatórias.</li>
          </ul>

          <Heading as="h2" size="lg1" weight="bold">
            Compartilhamento de Dados
          </Heading>
          <Text>
            Os dados podem ser compartilhados nas seguintes situações:
          </Text>

          <ul
            className="d-flex flex-column gap-2"
            style={{ listStyleType: 'disc', listStyle: 'inside' }}
          >
            <li>
              Com profissionais de saúde e hospitais credenciados, para
              continuidade do tratamento;
            </li>
            <li>
              Com órgãos reguladores, mediante exigência legal ou regulatória.
            </li>
          </ul>

          <Heading as="h2" size="lg1" weight="bold">
            Transferência Internacional de Dados
          </Heading>
          <Text>
            Em alguns casos, os dados pessoais podem ser transferidos para
            servidores localizados fora do Brasil. Quando isso ocorre,
            garantimos que tais transferências sejam feitas em conformidade com
            a LGPD, adotando medidas de segurança adequadas, como:
          </Text>

          <ul
            className="d-flex flex-column gap-2"
            style={{ listStyleType: 'disc', listStyle: 'inside' }}
          >
            <li>
              Contratos que garantam níveis adequados de proteção de dados.
            </li>
            <li>
              Adoção de mecanismos reconhecidos, como Cláusulas Padrão
              Contratuais.
            </li>
          </ul>

          <Heading as="h2" size="lg1" weight="bold">
            Segurança e Armazenamento dos Dados
          </Heading>
          <Text>
            Adotamos medidas técnicas e administrativas para proteger os dados,
            incluindo:
          </Text>

          <ul
            className="d-flex flex-column gap-2"
            style={{ listStyleType: 'disc', listStyle: 'inside' }}
          >
            <li>Criptografia e controle de acesso restrito;</li>
            <li>Controle de acesso restrito a usuários autorizados;</li>
            <li>Monitoramento contínuo e auditorias internas;</li>
            <li>Backup periódico para evitar perda de informações.</li>
          </ul>

          <Heading as="h2" size="lg1" weight="bold">
            Direitos dos Titulares dos Dados
          </Heading>
          <Text>Conforme a LGPD, os usuários têm os seguintes direitos:</Text>

          <ul
            className="d-flex flex-column gap-2"
            style={{ listStyleType: 'disc', listStyle: 'inside' }}
          >
            <li>Confirmação da existência de tratamento de seus dados;</li>
            <li>Acesso aos seus dados pessoais armazenados;</li>
            <li>Correção de informações incorretas ou desatualizadas;</li>
            <li>Solicitação de exclusão dos dados, quando aplicável;</li>
            <li>
              Revogação do consentimento para o uso de dados, quando aplicável.
            </li>
          </ul>

          <Text>
            Para exercer esses direitos, entre em contato com nosso Encarregado
            de Proteção de Dados pela página
            https://quality24.com.br/portaldotitular/.
          </Text>

          <Heading as="h2" size="lg1" weight="bold">
            Alterações na Política de Privacidade
          </Heading>
          <Text>
            Esta política poderá ser atualizada periodicamente para refletir
            mudanças nas práticas de tratamento de dados. Recomendamos que os
            usuários consultem esta página regularmente.
          </Text>

          <Heading as="h2" size="lg1" weight="bold">
            Contato
          </Heading>
          <Text>
            Caso tenha dúvidas sobre esta Política de Privacidade ou sobre o
            tratamento dos seus dados pessoais, entre em contato conosco pela
            página https://quality24.com.br/portaldotitular/.
          </Text>
        </div>
      </div>

      <SquareGraphic className={styles.graphic} />
    </MainTemplate>
  );
};

export default PrivacyPage;

export const Head: HeadFC = () => (
  <SEO
    title="Política de Privacidade"
    description="Leia aqui nossa Política de Privacidade"
  />
);
